html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rdw-editor-toolbar .rdw-option-wrapper {
  height: 2rem;
  width: 2rem;
}

#root {
  height: 100%;
  width: 100%;
  overflow-y: overlay;
}

#root::-webkit-scrollbar {
  display: block;
  width: 16px;
}

#root {
  --scrollbar-color: #00000000;
}

#root:hover,
#root:focus,
#root:focus-within {
  --scrollbar-color: #f9f9f9;
}

#root::-webkit-scrollbar-thumb {
  border: 5px solid transparent;
  border-radius: 24px;
  box-shadow: 4px 0px 0px 4px var(--scrollbar-color) inset;
}

/* .layout-container::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
  border: 0px solid transparent;
  box-shadow: none;
} */
